














import { Vue, Component, Prop } from 'vue-property-decorator';

import Icon from '@/shared/resources/components/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class ActionsItem extends Vue {
  /**
   * Props
   */
  @Prop() private title!: string;
  @Prop() private icon?: string;

  /**
   * Decide to display icon
   */
  private get displayIcon(): boolean {
    return !!this.icon;
  }

  /**
   * Handle click
   */
  private onClick() {
    return this.$emit('click');
  }
}
